import { Box, Typography, useTheme, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useState, useEffect } from "react";
import axios from "axios"; // Importa axios

const Sales = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [searchTerm, setSearchTerm] = useState(""); // Estado para la búsqueda
  const [sales, setSales] = useState([]); // Estado para almacenar los listings

  const authCookie = document.cookie.replace(/(?:(?:^|.*;\s*)auth\s*\=\s*([^;]*).*$)|^.*$/, "$1")

  useEffect(() => {

    axios.get(`https://mochalesdevapi.deno.dev/sales?auth=${authCookie}`)
    .then((response) => setSales(response.data))
    .catch((err) => console.error(err));
    
    
  }, [authCookie]);
  // Filtra los datos en función del término de búsqueda
  const filteredRows = sales.filter((row) =>
    row.event.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const columns = [
    { field: "site", headerName: "SITE", flex: 1, headerAlign: "center" },
    { field: "id", headerName: "SALE ID", flex: 1, headerAlign: "center" },
    { field: "sale_date", headerName: "SALE DATE", flex: 1, headerAlign: "center" },
    { field: "date", headerName: "DATE", flex: 1, headerAlign: "center" },
    { field: "event", headerName: "EVENT", flex: 1, headerAlign: "center" },
    { field: "category", headerName: "CATEGORY", flex: 1, headerAlign: "center" },
    { field: "section", headerName: "SECTION", flex: 1, headerAlign: "center" },
    { field: "row", headerName: "ROW", flex: 1, headerAlign: "center" },
    { field: "seating", headerName: "SEATING", flex: 1, headerAlign: "center" },
    { field: "qty", headerName: "QTY", type: "number", flex: 1, headerAlign: "center" },
    {
      field: "price",
      headerName: "SALE PRICE",
      type: "number",
      flex: 1,
      headerAlign: "center",
      renderCell: ({ row: { price } }) => (
        <Typography>{`£${price}`}</Typography>
      ),
    },
    {
      field: "status",
      headerName: "STATUS",
      flex: 1,
      headerAlign: "center",
      renderCell: ({ row: { status } }) => {
        const displayStatus = status || "Error"; // Si no hay status, muestra "Error"

        // Determina el color según el valor de status
        let backgroundColor;
        if (displayStatus === "Status 1") {
          backgroundColor = colors.yellow; // Amarillo para "Status 1"
        } else {
          backgroundColor = colors.green; // Verde para otros valores
        }

        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={backgroundColor}
            borderRadius="4px"
          >
            <Typography color={colors.white}>{displayStatus}</Typography>
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <Header title="SALES" subtitle="Managing the sales" />

      {/* Barra de búsqueda */}
      <Box mb="20px">
        <Box width="30%">
          <TextField
            fullWidth
            label="Search by Event Name"
            variant="outlined"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Box>
      </Box>

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            display: "flex",
            justifyContent: "center !important", // Fuerza el centrado horizontal
            alignItems: "center !important",    // Fuerza el centrado vertical
            textAlign: "center !important",     // Fuerza la alineación de texto
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.primary[300],
            borderBottom: "none",
            textAlign: "center", // Alineación del encabezado
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.primary[300],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={filteredRows}
          columns={columns}
          getRowId={(row) => row._id}
        />
      </Box>
    </Box>
  );
};

export default Sales;
