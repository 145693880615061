import { 
  Box, Button, Typography, useTheme, TextField, Dialog, DialogActions, DialogContent, DialogTitle
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import CircleIcon from '@mui/icons-material/Circle';
import EditIcon from '@mui/icons-material/Edit';
import Header from "../../components/Header";
import { useState, useEffect } from "react";
import axios from "axios";
import StatBox from "../../components/StatBox";
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import VerifiedIcon from '@mui/icons-material/Verified';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import PanToolIcon from '@mui/icons-material/PanTool';

const Listings = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [searchTerm, setSearchTerm] = useState("");
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");


  const authCookie = document.cookie.replace(/(?:(?:^|.*;\s*)auth\s*\=\s*([^;]*).*$)|^.*$/, "$1")

  useEffect(() => {
    axios
      .get(`https://mochalesdevapi.deno.dev/listings?auth=${authCookie}`)
      .then((response) => setListings(response.data))
      .catch((err) => console.error(err));
  }, [authCookie]);


  const getListingAnalytics = () => {
    const activeListings = listings.filter((listing) => listing.active === 'YES' && !listing.skip && listing.min_price !== '' && listing.max_price !== '')

    return {
      active: {
        number: activeListings.length,
        percentage: ((activeListings.length / listings.length) * 100).toFixed(0),
      },
      lowest: {
        number: activeListings.filter((listing) => listing.status === "Lowest price").length,
        percentage: ((activeListings.filter((listing) => listing.status === "Lowest price").length / activeListings.length) * 100).toFixed(0),
      },
      freezed: {
        number: activeListings.filter((listing) => listing.status === "Freezed").length,
        percentage: ((activeListings.filter((listing) => listing.status === "Freezed").length / activeListings.length) * 100).toFixed(0),
      },
      max: {
        number: activeListings.filter((listing) => listing.status === "Max price").length,
        percentage: ((activeListings.filter((listing) => listing.status === "Max price").length / activeListings.length) * 100).toFixed(0),
      }
    }
  }

  const handleUpdateMonitoring = async (rowId, currentStatus) => {
    setLoading(true);
    try {
      const response = await fetch(`https://mochalesdevapi.deno.dev/updateMonitoring?auth=${authCookie}`, {
        method: "POST",
        body: JSON.stringify({ id: rowId, monitoring: !currentStatus }),
      });

      const result = await response.json();
      if (result.updated) {
        setListings((prevListings) =>
          prevListings.map((listing) =>
            listing._id === rowId ? { ...listing, skip: currentStatus } : listing
          )
        );
      }
    } catch (error) {
      console.error("Error updating monitoring status", error);
    } finally {
      setLoading(false);
    }
  };

  const handleActionClick = (row) => {
    setSelectedRow(row);
    setMinPrice(row.min_price);
    setMaxPrice(row.max_price);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedRow(null);
  };

  const handleSave = async () => {
    if (!selectedRow) return;

    try {
      const response = await fetch(`https://mochalesdevapi.deno.dev/updatePrices?auth=${authCookie}`, {
        method: "POST",
        body: JSON.stringify({
          id: selectedRow._id,
          min_price: minPrice,
          max_price: maxPrice,
        }),
      });

      const result = await response.json();
      if (result.updated) {
        setListings((prevListings) =>
          prevListings.map((listing) =>
            listing._id === selectedRow._id
              ? { ...listing, min_price: minPrice, max_price: maxPrice }
              : listing
          )
        );
        setDialogOpen(false);
      } else {
        console.error("Failed to update prices");
      }
    } catch (error) {
      console.error("Error updating prices", error);
    }
  };

  const filteredRows = listings.filter((row) =>
    row.event.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const columns = [
    { field: "site", headerName: "SITE", flex: 1, headerAlign: "center" },
    { field: "date", headerName: "DATE", flex: 1, headerAlign: "center" },
    { field: "event", headerName: "EVENT NAME", flex: 1, headerAlign: "center" },
    { field: "category", headerName: "CATEGORY", flex: 1, headerAlign: "center" },
    { field: "section", headerName: "SECTION", flex: 1, headerAlign: "center" },
    { field: "row", headerName: "ROW", flex: 1, headerAlign: "center" },
    { field: "seating", headerName: "SEATING", flex: 1, headerAlign: "center" },
    { field: "qty", headerName: "QTY", type: "number", headerAlign: "center" },
    {
      field: "min_price",
      headerName: "MIN PRICE",
      type: "number",
      flex: 1,
      headerAlign: "center",
      renderCell: ({ row: { min_price } }) => (
        <Typography>{`£ ${min_price}`}</Typography>
      ),
    },
    {
      field: "max_price",
      headerName: "MAX PRICE",
      type: "number",
      flex: 1,
      headerAlign: "center",
      renderCell: ({ row: { max_price } }) => (
        <Typography>{`£ ${max_price}`}</Typography>
      ),
    },
    {
      field: "selling_price",
      headerName: "LISTING PRICE",
      type: "number",
      flex: 1,
      headerAlign: "center",
      renderCell: ({ row: { selling_price } }) => (
        <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            backgroundColor={colors.primary[300]}
            borderRadius="4px"
            textAlign="center"
          >
            <Typography
              color={colors.white}
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              £ {selling_price}
            </Typography>
          </Box>
      ),
    },
    {
      field: "active",
      headerName: "ACTIVE",
      headerAlign: "center",
      renderCell: ({ row: { active } }) => {
        const displayStatus = active === "YES" ? <DirectionsRunIcon/> : <PanToolIcon/>;
        const displayColor = active === "YES" ? colors.green : colors.red;
  
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            color = {displayColor}
            borderRadius="4px"
            textAlign="center"
          >
            {displayStatus}
          </Box>
        );
      },
    },    
    {
      field: "status",
      headerName: "STATUS",
      flex: 1,
      headerAlign: "center",
      renderCell: ({ row: { status } }) => {
        const displayStatus = status || "Sleeping"; // Si no hay status, muestra "Error"
    
        // Determina el color según el valor de status
        let backgroundColor;
        if (displayStatus === "Max price") {
          backgroundColor = colors.yellow; // Amarillo para "max price"
        } else if (displayStatus === "Freezed") {
          backgroundColor = colors.blue; // Azul para "freezed"
        } else if (displayStatus === "Lowest price") {
          backgroundColor = colors.green; // Verde para "Lowest price"
        } else if (displayStatus === "Sleeping") {
          backgroundColor = colors.primary[300]; // Verde para "Lowest price"
        } else {
          backgroundColor = colors.red; // Rojo para otros valores
        }
    
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            backgroundColor={backgroundColor}
            borderRadius="4px"
            textAlign="center"
          >
            <Typography
              color={colors.white}
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {displayStatus}
            </Typography>
          </Box>
        );
      },
    },    
    {
      field: "skip",
      headerName: "ACTIONS",
      flex: 1,
      headerAlign: "center",
      renderCell: ({row, row: { skip, _id } }) => {
        const isMonitoring = !skip;
        const buttonColor = isMonitoring ? colors.green : colors.red;
        return (
          <Box display="flex" justifyContent="center" alignItems="center" width="100%">
            <Button
              variant="contained"
              color="primary"
              sx={{
                fontWeight: "bold",
                borderRadius: "30px",
                backgroundColor: colors.primary[400],
                "&:hover": {
                  backgroundColor: colors.primary[400],
                },
                boxShadow: "none",
                margin: "0 5px",
              }}
              onClick={() => handleUpdateMonitoring(_id, isMonitoring)}
              disabled={loading}
            >
              <CircleIcon style={{ color: buttonColor }} />
            </Button>
              <Button
              variant="contained"
              color="primary"
              sx={{
                fontWeight: "bold",
                borderRadius: "30px",
                backgroundColor: colors.primary[400],
                "&:hover": {
                  backgroundColor: colors.primary[400],
                },
                boxShadow: "none",
              }}
              onClick={() => handleActionClick(row)}
            >
              <EditIcon style={{ color: colors.yellow }} />
          </Button>
          </Box>
        );
      },
    }
  ];

  const listingsAnalytics = getListingAnalytics();

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="LISTINGS" subtitle="Managing the remaining listings" />

        <Box
          sx={{
            display: "flex",
            gap: "10px",
            padding: "10px",
            borderRadius: "8px",
          }}
        >
          <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="100px"
          gap="5px"
          padding="20px"
        >
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="10px"
          >
            <StatBox
              title={listingsAnalytics.active.number}
              subtitle="Monitoring"
              progress={(listingsAnalytics.active.percentage)/100}
              increase={`+${listingsAnalytics.active.percentage}%`}
              icon={
                <DirectionsRunIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="10px"
          >
            <StatBox
              title={listingsAnalytics.lowest.number}
              subtitle="Lowest Price"
              progress={(listingsAnalytics.lowest.percentage)/100}
              increase={`+${listingsAnalytics.lowest.percentage}%`}
              icon={
                <VerifiedIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="10px"
          >
            <StatBox
              title={listingsAnalytics.freezed.number}
              subtitle="Freezed"
              progress={(listingsAnalytics.freezed.percentage)/100}
              increase={`+${listingsAnalytics.freezed.percentage}%`}
              icon={
                <AcUnitIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="10px"
          >
            <StatBox
              title= {listingsAnalytics.max.number}
              subtitle="Max Price"
              progress={(listingsAnalytics.max.percentage)/100}
              increase={`+${listingsAnalytics.max.percentage}%`}
              icon={
                <DirectionsBoatIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
        </Box>
        </Box>


        <Box>
        </Box>
      </Box>

      
      <Box mb="20px" display="flex" justifyContent="space-between" alignItems="center">
        <Box width="30%">
          <TextField
            fullWidth
            label="Search by Event Name"
            variant="outlined"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Box>
        <Box>
        </Box>
      </Box>

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            display: "flex",
            justifyContent: "center !important",
            alignItems: "center !important",
            textAlign: "center !important",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.primary[300],
            borderBottom: "none",
            textAlign: "center",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.primary[300],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "inherit", // Mantén el color original
          },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={filteredRows}
          columns={columns}
          getRowId={(row) => row._id}
        />
      </Box>

      {/* Dialog */}
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle color={colors.greenAccent[400]}>UPDATE PRICES</DialogTitle>
        <DialogContent>
          {selectedRow && (
            <Box>
              <Typography>{selectedRow.event}</Typography>
              <Typography>{selectedRow.category} {selectedRow.section} {selectedRow.row} x {selectedRow.qty}</Typography>
              <Typography color={colors.greenAccent[400]}>{`₤ ${selectedRow.selling_price} ${selectedRow.status || 'Error'}`}</Typography>
              <TextField
                fullWidth
                margin="normal"
                label="Min Price"
                value={minPrice}
                onChange={(e) => setMinPrice(e.target.value)}
                type="number"
              />
              <TextField
                fullWidth
                margin="normal"
                label="Max Price"
                value={maxPrice}
                onChange={(e) => setMaxPrice(e.target.value)}
                type="number"
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDialogClose}
            sx={{
              backgroundColor: colors.redAccent[400], // Color de fondo del botón
              color: "white",
              "&:hover": {
                backgroundColor: colors.redAccent[600], // Color más oscuro al pasar el mouse
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            sx={{
              backgroundColor: colors.greenAccent[400],
              color: "white",
              "&:hover": {
                backgroundColor: colors.greenAccent[600],
              },
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Listings;
