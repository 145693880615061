import { Box, IconButton, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const handleLogout = () => {
    // Eliminar la cookie auth
    document.cookie = "auth=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    // Redirigir a la página de login
    navigate("/login");
  };

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
      </Box>
      <Box display="flex">
        <IconButton
          sx={{
            backgroundColor: colors.red,
            color: "white",
            "&:hover": {
              backgroundColor: colors.redAccent[600],
            },
          }}
          onClick={handleLogout} // Asignar el evento al botón
        >
          <LogoutIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Topbar;
