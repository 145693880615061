import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { tokens } from "../../theme";
import Header from "../../components/Header";

const Login = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Estados para email y contraseña
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  // Función para codificar en Base64
  const encodeBase64 = (str) => btoa(str);

  // Función para establecer una cookie
  const setCookie = (name, value, days) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
  };

  // Función para manejar el envío del formulario
  const handleLogin = async () => {

    setLoading(true);

    if (!email || !password) {
      alert("Please fill in both fields.");
      setLoading(false);
      return;
    }

    try {
      const response = await fetch("https://mochalesdevapi.deno.dev/login", {
        method: "POST",
        body: JSON.stringify({ email, password }),
      });

      const result = await response.json();

      if (result.isValid) {

        const authValue = encodeBase64(`${email}:${password}`);
        setCookie("auth", authValue, 365); 
        window.location.href = "/";

      } else {
        alert("Login failed. Please try again.");
      }
    } catch (error) {
      alert("An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box m="20px">
      <Header title="LOGIN" subtitle="If you are a client of Mochaaless, please login" />

      {/* Contenedor del formulario */}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        p="20px"
        borderRadius="8px"
        boxShadow="0px 4px 10px rgba(0, 0, 0, 0.2)"
        backgroundColor={colors.primary[400]}
        width="400px"
        mx="auto"
        mt="40px"
      >
        <Typography variant="h5" mb="20px" color={colors.grey[100]}>
          Welcome Back!
        </Typography>

        {/* Campo de texto para el correo */}
        <TextField
          fullWidth
          label="Email Address"
          variant="outlined"
          margin="normal"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        {/* Campo de texto para la contraseña */}
        <TextField
          fullWidth
          label="Password"
          variant="outlined"
          margin="normal"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        {/* Botón para iniciar sesión */}
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 2, width: "100%", padding: "10px", fontWeight: "bold" }}
          onClick={handleLogin}
          disabled={loading}
        >
          {loading ? "Logging in..." : "Login"}
        </Button>
      </Box>
    </Box>
  );
};

export default Login;
